var render = function render(){var _vm=this,_c=_vm._self._c;return _c('y-layout-body-menu',{attrs:{"aside-width":"9rem","menu":[
  {title:'当前首页',path:'/card/plugin/home-page/publish-log'},
  {title:'极速首页',path: '/card/plugin/home-page/fast-page',children:[
      {title:'基础配置',path:'/card/plugin/home-page/fast-page/conf'},
      {title:'轮播图',path:'/card/plugin/home-page/fast-page/banner'},
      {title:'宫格导航',path:'/card/plugin/home-page/fast-page/gird'},
      {title:'自定义菜单',path:'/card/plugin/home-page/fast-page/tabbar'},
      {title:'浮动菜单',path:'/card/plugin/home-page/fast-page/float-bar'},
  ]},
  {title:'视频首页',path: '/card/plugin/home-page/video-page',children:[
      {title:'基础配置',path:'/card/plugin/home-page/video-page/conf'},
      {title:'虚拟评论',path:'/card/plugin/home-page/video-page/comment'},
      {title:'红包发放记录',path:'/card/plugin/home-page/video-page/video-count-down-award-log'},
      {title:'视频管理',path:'/card/plugin/home-page/video-page/video'},
      {title:'自定义菜单',path:'/card/plugin/home-page/video-page/tabbar'},
  ]},
]}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }